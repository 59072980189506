(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/bet-actions/views/bet-actions.js') >= 0) return;  svs.modules.push('/components/banner_actions/bet-actions/views/bet-actions.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.bet_actions=_svs.banner_actions.bet_actions||{};
_svs.banner_actions.bet_actions.templates=_svs.banner_actions.bet_actions.templates||{};
svs.banner_actions.bet_actions.templates.bet_actions = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"betActions") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":20,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a\n         href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkUrl") || (depth0 != null ? lookupProperty(depth0,"linkUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":26}}}) : helper)))
    + "\"\n         class=\"btn btn-default btn-bet-offer"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"block") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":79}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":79},"end":{"line":5,"column":109}}})) != null ? stack1 : "")
    + "\"\n         data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":18},"end":{"line":6,"column":24}}}) : helper)))
    + "\"\n         data-odds=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"odds") || (depth0 != null ? lookupProperty(depth0,"odds") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"odds","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":28}}}) : helper)))
    + "\"\n         data-click-tracking=\""
    + alias4(container.lambda((depths[2] != null ? lookupProperty(depths[2],"clickTrackingString") : depths[2]), depth0))
    + "\"\n         "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"action") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":53}}})) != null ? stack1 : "")
    + "\n         "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":9},"end":{"line":10,"column":57}}})) != null ? stack1 : "")
    + "\n         title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkTitle") || (depth0 != null ? lookupProperty(depth0,"linkTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkTitle","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":29}}}) : helper)))
    + "\"\n      >\n         <div class=\"btn-bet-text-wrapper\">\n            <div class=\"btn-bet-ellipsis\">\n               <span class=\"btn-bet-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"linkTitle") || (depth0 != null ? lookupProperty(depth0,"linkTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkTitle","hash":{},"data":data,"loc":{"start":{"line":15,"column":42},"end":{"line":15,"column":55}}}) : helper)))
    + "</span>\n            </div>\n            <span class=\"btn-bet-odds\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"linkOdds") || (depth0 != null ? lookupProperty(depth0,"linkOdds") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkOdds","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":51}}}) : helper)))
    + "</span>\n         </div>\n      </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"btn-block") || (depth0 != null ? lookupProperty(depth0,"btn-block") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"btn-block","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":72}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":5,"column":93},"end":{"line":5,"column":102}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "rel=\""
    + container.escapeExpression((lookupProperty(helpers,"getRel")||(depth0 && lookupProperty(depth0,"getRel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"action") : depth0),{"name":"getRel","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":45}}}))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "target=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkTarget") || (depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkTarget","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":49}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"action") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
Handlebars.partials['components-banner_actions-bet_actions-bet_actions'] = svs.banner_actions.bet_actions.templates.bet_actions;
})(svs, Handlebars);


 })(window);